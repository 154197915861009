<template>
    <div>
        <div class="price">
            <span>￥{{arrs.price.toFixed(2)}}</span>
        </div>
        <div class="card">
            <van-cell-group>
                <van-cell title="客户剩余短信条数" :value="arrs.smsBalance + arrs.smsCount"/>
                <van-cell title="客户发送短信条数" :value="arrs.totalSendCount"/>
                <van-cell title="短信单价" :value="arrs.smsPrice"/>
            </van-cell-group>
        </div>
        <div class="button">
            <van-button color="#EB5E00" size="large" @click="submit">返回首页</van-button>
        </div>
    </div>
</template>
<script>
export default {
    name:'smsPay',
    data() {
        return {
            arrs:{}
        }
    },
    methods: {
        submit(){
            // this.$api.addToUpPack(this.arrs).then(res => {
            //     if(res.data.code == 0){
            //         localStorage.upOrder = res.data.data
            //         localStorage.removeItem('uppack');
            //     }
            // })
            localStorage.removeItem('sms');
            this.$router.push({name:'Home'});
        }
    },
    created() {
        this.$store.commit('setPageTitle', "短信支付订单");
        if(!localStorage.sms){
            this.$toast.fail("未发现订单");
            // this.$router.push({name:'smsCharge'})
            this.$router.go(-1)
        }else{
            this.arrs = JSON.parse(localStorage.sms)
        }
    }
}
</script>
<style lang="scss" scoped>
.price{
    span{
        color: #EB5E00;font-size: 60px;
    }
}
.card{
    text-align: left;
}
.button{
    margin-top: 50px
}
</style>
    
